<template>
  <div>
    <vuestic-widget :loading="loading">
      <div class="listPaymentMethods">
        <div class="paymentBox" v-for="payment in paymentMethods" :key="payment.id">
          <div class="cardBrand">{{ payment.brand }}</div>
          <div class="cardNumber">{{ payment.brand }} ****{{ payment.last_four }}</div>
          <div class="cardExpiration">Expires: {{ payment.exp_month }} / {{ payment.exp_year }}</div>
          <div class="actions">
            <a class="default" @click="setDefaultPaymentMethod(payment.id)" v-if="!payment.default"
              :disabled="loadingSetDefault">
              <atom-spinner v-if="loadingSetDefault" slot="loading" :animation-duration="1500" :size="14"
                color="#3578c6" />
              Set default
            </a>
            <a class="remove" @click="removePaymentMethod(payment.id)" :disabled="loadingRemove"
              v-if="paymentMethods.length > 1 && !payment.default">
              <atom-spinner v-if="loadingRemove" slot="loading" :animation-duration="1500" :size="14" color="#e40b0b" />
              Remove
            </a>
          </div>
        </div>
      </div>
      <button class="btn btn-sm btn-primary" @click="addPaymentMethod()">
        <i class="fa fa-plus"></i> Add payment method
      </button>
    </vuestic-widget>

    <vuestic-widget v-if="isAddPaymentMethod">
      <AddCreditCard buttonText="Add payment method" :show-coupon="false" v-on:done="addedPaymentMethod"></AddCreditCard>
    </vuestic-widget>
  </div>
</template>

<script>
import AddCreditCard from '@/components/common/AddCreditCard.vue'

export default {
  components: { AddCreditCard, },

  data() {
    return {
      isAddPaymentMethod: false,
      loadingRemove: false,
      loadingSetDefault: false,
    }
  },

  mounted() {
    if (!this.hasAgencyStripeConfig) {
      this.$router.push({ name: 'business.setting' })
      return;
    }
    this.getPaymentMethods()
  },

  methods: {
    getPaymentMethods() {
      this.$store.dispatch('subscription/getPaymentMethods')
        .catch(err => { })
    },

    addPaymentMethod() {
      this.isAddPaymentMethod = true
    },

    removePaymentMethod(paymentMethodId) {
      this.loadingRemove = true

      this.$store.dispatch('subscription/removePaymentMethod', paymentMethodId)
        .then((res) => {
          this.loadingRemove = false
        })
        .catch((errors) => {
          this.loadingRemove = false
        })
    },

    setDefaultPaymentMethod(paymentMethodId) {
      this.loadingSetDefault = true

      this.$store.dispatch('subscription/setDefaultPaymentMethod', paymentMethodId)
        .then((res) => {
          this.loadingSetDefault = false
        })
        .catch((errors) => {
          this.loadingSetDefault = false
        })
    },

    addedPaymentMethod() {
      this.isAddPaymentMethod = false
      this.getPaymentMethods()
    },

    async checkCard() {
      const { setupIntent, error } = await this.stripe.confirmCardSetup(this.clientSecret, {
        payment_method: {
          card: this.cardElement,
        },
      })

      this.error = null
      if (error) {
        this.error = error.message
      } else {
        this.$store.dispatch('subscription/addPaymentMethod', setupIntent.payment_method)
      }
    },
  },

  computed: {
    loading() {
      return this.$store.state.subscription.loading
    },

    paymentMethods() {
      return this.$store.getters['subscription/paymentMethods']
    },

    hasAgencyStripeConfig() {
      return this.$store.getters['auth/hasAgencyStripeConfig'];
    },
  },
}
</script>

<style scoped>
.listPaymentMethods {
  margin-bottom: 20px
}

.listPaymentMethods .paymentBox {
  border-bottom: 1px dotted #cbd2d6;
  padding: 10px 0px;
}

.listPaymentMethods .paymentBox .cardBrand {
  font-size: 16px;
  font-weight: 500;
  color: #2c2e2f;
  text-transform: uppercase;
}

.listPaymentMethods .paymentBox .cardNumber,
.listPaymentMethods .paymentBox .cardExpiration {
  color: #666;
  font-weight: 500;
}

.listPaymentMethods .paymentBox .actions {
  margin-top: 10px
}

.listPaymentMethods .paymentBox .actions a {
  color: #3578c6;
  display: inline-block;
  margin-right: 10px;
}

.listPaymentMethods .paymentBox .actions a[disabled] {
  opacity: 0.6;
}

.listPaymentMethods .paymentBox .atom-spinner {
  display: inline-block;
}

.listPaymentMethods .paymentBox .actions .remove {
  color: #e40b0b;
}</style>
